.notification {
    composes: notification from './notifications.vars.css';
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 18px;
    z-index: 10000;
    box-shadow: var(--notification-box-shadow);
    height: auto;
    padding: 27px 52px 27px 26px;
    line-height: 28px;
}

.notification.errorNotification {
    background: var(--notification-errorNotification-background);
}

.notification.errorNotification *,
.errorNotification .notificationIcon::before {
    color: var(--notification-error-text-color);
}

.notification.successNotification {
    background: var(--notification-success-background);
    border: var(--notification-success-border);
    border-radius: var(--notification-success-border-radius);
}

.notification.successNotification *,
.successNotification .notificationIcon::before {
    color: var(--col--light);
}

.successNotification .closeIcon {
    display: var(--notification-close-icon-display);
}

.closeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    transform: translateY(-50%);
    right: 26px;
    width: 26px;
    height: 26px;
    font-size: 16px;
    cursor: pointer;
}

.notificationContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 26px;
}

.notificationIcon {
    margin: 0 12px 0 0;
}

.successNotification .textBlock {
    display: flex;
    align-items: center;
}

.message,
.title {
    line-height: var(--notification-line-height);
}

.title {
    text-transform: var(--notification-title-text-transform);
}

.message {
    padding-left: var(--notification-message-padding-left);
    overflow: hidden;
    text-transform: none;
}

.errorNotification .message {
    text-decoration: var(--notification-error-message-decoration);
}

@media screen and (max-width: 961px) {
    .notification.successNotification {
        margin: var(--notification-success-mobile-margin);
    }

    .notification {
        height: auto;
        padding: 20px;
        line-height: 28px;
    }

    .notificationContent {
        flex-direction: column;
        height: auto;
    }

    .successNotification .notificationContent {
        flex-direction: var(--notification-success-notification-content-mobile-flex-direction);
    }

    .successNotification .notificationIcon {
        align-self: var(--notification-success-notification-icon-mobile-align-self);
        font-size: var(--notification-success-icon-font-size);
    }

    .successNotification .textBlock {
        flex-direction: column;
        align-items: initial;
    }

    .notificationIcon {
        margin: 0 0 var(--notification-icon-mobile-margin) 0;
    }

    .message,
    .title {
        font-size: var(--notification-text-mobile-font-size);
        line-height: var(--notification-text-mobile-line-height);
        padding-left: 0;
        text-align: center;
    }

    .successNotification .message,
    .successNotification .title {
        padding-left: var(--notification-success-title-message-mobile-padding-left);
        text-align: var(--notification-success-title-message-mobile-text-align);
    }

    .closeIcon {
        font-size: 14px;
        top: 34px;
        right: 20px;
    }
}
