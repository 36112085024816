.notification {
    --notification-errorNotification-background: var(--col--primary1);
    --notification-icon-mobile-margin: 14px;
    --notification-text-mobile-font-size: 16px;
    --notification-text-mobile-line-height: 24px;
    --notification-error-text-color: var(--col--light);
    --notification-error-message-decoration: unset;
    --notification-message-padding-left: 5px;
    --notification-title-text-transform: uppercase;
    --notification-line-height: 28px;
    --notification-success-mobile-margin: 18px;
    --notification-success-border: 1px solid var(--col--light);
    --notification-box-shadow: 0px 0px 30px var(--col--dark);
    --notification-success-border-radius: 0;
    --notification-success-background: var(--col--dark);
    --notification-close-icon-display: block;
    --notification-success-icon-font-size: 24px;
    --notification-success-notification-content-mobile-flex-direction: column;
    --notification-success-notification-icon-mobile-align-self: center;
    --notification-success-title-message-mobile-padding-left: 0;
    --notification-success-title-message-mobile-text-align: center;
}
